import {
  logger
} from '../logger/Logger';
import {
  analyticsProvidersExtensions as extensions
} from './providers/providersExtensions';

const STATES = {
  NOT_REGISTERED: 0,
  REGISTERING_ONLY: 1,
  REGISTERING_AND_OPTING_IN: 2,
  REGISTERED: 3,
  FAILED_TO_REGISTER: 4
}

class ViewerAnalytics {
  constructor() {
    this.providers = {};

    Object.keys(extensions).forEach(name => {
      this.providers[name] = {
        instance: null,
        extensionId: extensions[name],
        state: STATES.NOT_REGISTERED,
        cache: []
      };
    })
  }

  /**
   * @param {object} props - properties that are sent with every track request
   */
  setSuperProps(props) {
    this.superProps = props;
  }

  register(names = 'all', callback) {
    const providers = names === 'all' ? Object.keys(this.providers) : Array.isArray(names) ? names : [names];

    providers.forEach((name) => {
      if (this.providers[name].state === STATES.REGISTERED) {
        logger.warn(`Analytics provider '${name}' is already registered`);
        if (callback) {
          callback(name);
        }
      } else {
        if (!this.providers[name]) {
          throw new Error(`Provider '${name}' has no extensions mapped to register`);
        }

        if (this.providers[name].state === STATES.REGISTERING_ONLY) {
          logger.warn(`Analytics provider '${name}' is already being registered`);
          return;
        }

        if (!callback) {
          this.providers[name].state = STATES.REGISTERING_ONLY;
        }

        Autodesk.Viewing.theExtensionManager.downloadExtension(this.providers[name].extensionId).then((extension) => {
          try {
            const provider = new extension.providerClass(extension.providerOptions);
            provider.init();

            if (this.superProps) {
              provider.register(this.superProps);
            }

            this.providers[name].instance = provider;

            if (callback) {
              callback(name);
            }

            this.providers[name].state = STATES.REGISTERED;
            logger.info(`Analytics provider '${provider.name}' successfully registered`);


            if (this.providers[name].cache.length > 0) {
              this.providers[name].cache.forEach(data => {
                this.providers[name].instance.track(data.event, data.properties)
              });

              this.providers[name].cache = [];
            }

          } catch (e) {
            this.providers[name].state = STATES.FAILED_TO_REGISTER;
            this.providers[name].cache = [];

            logger.error(`Failed to register analytics provider: "${name}"`, e);
          }
        });
      }
    });
  }

  optIn(options, names = 'all') {
    const providersNames = names === 'all' ? Object.keys(this.providers) : Array.isArray(names) ? names : [names];

    providersNames.forEach((providerName) => {
      logger.info(`Trying to opt-in analytics provider '${providerName}'`);

      const registerProviderCallback = (name) => {
        if (this.providers[name].state === STATES.REGISTERING_AND_OPTING_IN) {
          this.providers[name].state = STATES.REGISTERED;
        }

        if (this.providers[name].state !== STATES.REGISTERED) {
          throw new Error(`Analytics provider '${name}' can not be opted-in because is not registered`);
        } else if (this.providers[name].instance.hasOptedOut()) {
          this.providers[name].instance.optIn(options);
          logger.info(`Analytics provider '${name}' opted-in`);
        } else {
          logger.warn(`Analytics provider '${name}' is already opted-in`);
        }
      }

      if (!this.providers[providerName]) {
        throw new Error(`No extension for '${providerName}' analytics available`);
      }

      switch (this.providers[providerName].state) {
        case STATES.REGISTERED: //Try to opt-in if is already loaded
          registerProviderCallback(providerName);
          break;

        case STATES.FAILED_TO_REGISTER:
          throw new Error(`Can't opt-in '${providerName}' because registering failed`);

        case STATES.NOT_REGISTERED: //If is not registered, do that
        case STATES.REGISTERING_ONLY: //Is being registered by someone, but not trying to be opted-in, try to opt-in
        this.providers[providerName].state = STATES.REGISTERING_AND_OPTING_IN;
          this.register(providerName, registerProviderCallback);
          break;

        case STATES.REGISTERING_AND_OPTING_IN: //Already listening with a callback
        default:
          return;
      }
    });
  }

  optOut(options, names = 'all') {
    this._getProviders(names).forEach(p => p.instance.optOut(options));
  }

  hasOptedOut(name) {
    this._checkUniqueProviderName(name);
    name = name.toLowerCase();

    const provider = this._getProviders(name)[0];
    return provider.instance.hasOptedOut();
  }

  getDistinctId(name) {
    this._checkUniqueProviderName(name);
    name = name.toLowerCase();

    const provider = this._getProviders(name)[0];
    return provider.instance.getDistinctId();
  }

  track(event, properties, names = 'all') {
    const providerNames = names === 'all' ? Object.keys(this.providers) : Array.isArray(names) ? names : [names];

    providerNames.forEach((providerName) => {

      if (!this.providers[providerName]) {
        throw new Error(`Analytics provider '${providerName}' has no extension available to load`);
      } else if (this.providers[providerName].state === STATES.REGISTERED) {
        this.providers[providerName].instance.track(event, properties);
      } else if (this.providers[providerName].state === STATES.REGISTERING_ONLY) {
        const data = { //Data to track later
          event: event,
          properties: properties
        };

        this.providers[providerName].cache.push(data);
      } //STATES.UNLOADED or STATES.FAILED are ignored

    });
  }

  identify(distinctId, names = 'all') {
    this._getProviders(names).forEach(p => p.instance.identify(distinctId));
  }

  _checkUniqueProviderName(name) {
    if (typeof name !== 'string' || name === '') {
      throw new Error(`Analytics provider must be a registered non-empty string`)
    } else if (name === 'all') {
      throw new Error(`Analytics provider name cannot be 'all'`);
    }
  }

  _getProviders(names) {
    let keys;

    if (Array.isArray(names)) {
      keys = names.map((x) => x.toLowerCase());
    } else if (names === 'all') {
      keys = Object.keys(this.providers);
    } else {
      keys = [names.toLowerCase()];
    }

    keys.forEach(name => {

      if (!(name in this.providers)) {
        throw new Error(`Analytics provider '${name}' has no extension to load`);
      } else if (this.providers[name].state === STATES.REGISTERING_ONLY) {
        throw new Error(`Analytics provider '${name}' is being registered`);
      } else if (this.providers[name].state === STATES.NOT_REGISTERED) {
        throw new Error(`Analytics provider '${name}' is not registered`);
      } else if (this.providers[name].state === STATES.FAILED_TO_REGISTER) {
        throw new Error(`Analytics provider '${name}' could not be registered`);
      }

    });

    return keys.map((k) => this.providers[k]);
  }
}

const instance = new ViewerAnalytics();
export {
  instance as analytics
};